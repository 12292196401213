

































import Vue from "vue";
import { SearchAnything } from "@/interfaces/recruiter/dashboard/search_anything";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ADVANCE_SEARCH,
  SEARCH_TEXT
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "SearchAnything",
  data(): SearchAnything & { loading: boolean } {
    return {
      form: false,
      search: "",
      search_rules: [
        (value: string) =>
          !!value || this.$t("candidate.jobs.field-required-rule")
      ],
      loading: false
    };
  },
  watch: {
    get_search_text(val) {
      this.search = val;
    }
  },
  props: {
    on_dashboard: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters("recruiter", {
      get_search_text: SEARCH_TEXT
    })
  },
  mounted() {
    if (!this.on_dashboard) {
      this.search = this.get_search_text;
    }
  },
  methods: {
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    ...mapMutations("recruiter", {
      set_search_text: SEARCH_TEXT
    }),
    ...mapActions("recruiter", {
      advance_search: ADVANCE_SEARCH
    }),
    async fetch_data() {
      this.loading = true;
      if (this.search) {
        const response = await this.advance_search(this.search);
        if (response && response.data?.length) {
          this.set_search_text(this.search);
          sessionStorage.setItem("advance_search_user", response.data);
          this.root_notification(response.msg);
          this.$router.push({
            path: "candidates"
          });
        } else {
          this.set_search_text("");
          this.root_error(response?.msg);
        }
      }
      this.loading = false;
    },
    on_input(text: string) {
      // If this component is used on dashboard then emit the input event to parent component for candidate search
      if (!this.on_dashboard) {
        this.$emit("on_input", text);
      }
    }
  }
});
