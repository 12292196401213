var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-card rd-mcc matching-candidates-card"},[_c('div',{staticClass:"rd-mcc-h d-flex align-center justify-space-between"},[_c('h2',{staticClass:"base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.dashboard.matching-candidates-title"))+" ")]),(_vm.top_posted_jobs.results.length <= 0)?[_c('v-btn',{staticClass:"base-submit-button rd-mcc-h-post-job-btn",attrs:{"to":"jobs/post-job"}},[_vm._v(" "+_vm._s(_vm.$t("nav-sidebar.post-job"))+" ")])]:[_c('v-select',{staticClass:"rd-mcc-h-jd px-5 py-2 ma-0",attrs:{"items":_vm.top_posted_jobs.results,"item-value":"jid","item-text":"job_title","return-object":"","single-line":"","loading":_vm.get_posted_jobs_loading,"hide-details":""},model:{value:(_vm.selected_job),callback:function ($$v) {_vm.selected_job=$$v},expression:"selected_job"}})]],2),_c('div',{staticClass:"matching-candidates-card-body"},[(
        _vm.top_posted_jobs.results.length <= 0 ||
        (_vm.selected_job &&
          !_vm.selected_job.candidates_loading &&
          _vm.selected_job.matching_candidates.length <= 0)
      )?[_c('DataNotFound',{staticClass:"my-auto no-matching-candidates",attrs:{"title":_vm.getTitle()}})]:(_vm.selected_job && _vm.selected_job.candidates_loading)?[_c('div',{staticClass:"mt-10 matching-candidates-loading"},_vm._l((5),function(index){return _c('v-skeleton-loader',{key:index,staticClass:"matching-candidates-loading-loader",attrs:{"type":"list-item-avatar"}})}),1)]:(
        _vm.selected_job &&
        !_vm.selected_job.candidates_loading &&
        _vm.selected_job.matching_candidates.length > 0
      )?[_c('div',{staticClass:"mt-5"},_vm._l((_vm.selected_job.matching_candidates.slice(0, 6)),function(candidate){return _c('SingleCandidate',{key:candidate.candidate_id * _vm.generate_random_key(),attrs:{"candidate":candidate,"job_id":_vm.selected_job.jid}})}),1)]:(
        _vm.selected_job &&
        !_vm.selected_job.candidates_loading &&
        _vm.selected_job.matching_candidates.results.length > 0
      )?[_c('div',{staticClass:"mt-5"},_vm._l((_vm.selected_job.matching_candidates.results.slice(
            0,
            6
          )),function(candidate){return _c('SingleCandidate',{key:candidate.candidate_id * _vm.generate_random_key(),attrs:{"candidate":candidate,"job_id":_vm.selected_job.jid}})}),1)]:_vm._e()],2),(
      _vm.top_posted_jobs.results.length > 0 &&
      _vm.selected_job &&
      _vm.selected_job.matching_candidates.length > 0
    )?_c('div',{staticClass:"matching-candidates-card-card-footer mt-n5 text-center"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.view_all_candidates(_vm.selected_job)}}},[_vm._v(" View More ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }