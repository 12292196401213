





















































import Vue, { PropType } from "vue";
import {
  get_color_by_matching_score,
  get_random_color_hash
} from "@/utils/colors";
import { mapGetters } from "vuex";
import {
  FetchCandidateDetailsReturn,
  fetch_candidate_details
} from "@/utils/global";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SearchedCandidate } from "@/store/modules/recruiter/interfaces";
export default Vue.extend({
  name: "SingleCandidate",
  data() {
    return {
      box_size: 75,
      score_size: 60,
      font_color: "",
      background_color: "",
      color_code: "",
      name: "",
      designation: "",
      score: null as number | null,
      profile_picture: null as string | null,
      name_initials: null as string | null
    };
  },

  watch: {
    breakpoints() {
      if (
        this.$vuetify.breakpoint.width >= 1264 &&
        this.$vuetify.breakpoint.width <= 1550
      ) {
        this.box_size = 50;
      } else {
        this.box_size = 75;
      }
    }
  },
  mounted() {
    // Fetching candidate details
    if (this.candidate.upload_response.profile) {
      const data: FetchCandidateDetailsReturn = fetch_candidate_details(
        this.candidate.upload_response.profile,
        this.candidate.score ?? 0
      );
      this.name = data.name;
      this.designation = data.designation;
      this.name_initials = data.name_initials;
      this.score = data.score;
    }
    // const color = get_random_color(); // Get random color
    const color = get_color_by_matching_score(
      this.candidate.score ? this.candidate.score : 0
    );
    this.font_color = `color: ${color}`;
    this.background_color = `background: ${color}`;
    this.color_code = color;
  },
  computed: {
    breakpoints(): number {
      return this.$vuetify.breakpoint.width;
    },
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  props: {
    candidate: {
      type: Object as PropType<SearchedCandidate>,
      required: true
    },
    job_id: {
      type: Number,
      required: true
    }
  },
  methods: {
    get_random_color_hash,
    /**
     * Function to navigate to candidates page setting candidate view payload
     */
    async view_candidate() {
      sessionStorage.setItem("job", this.job_id.toString());
      sessionStorage.setItem("user", this.candidate.candidate_id.toString());
      sessionStorage.setItem("upload", this.candidate.upload_response_id);
      await this.$router.push("/recruiter/candidates/profile");
    }
  }
});
