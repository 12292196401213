

























































































































import Vue from "vue";
import { DashboardNumbers } from "@/interfaces/recruiter/dashboard/dashboard_numbers";
import { mapActions } from "vuex";
import { ALL_JOBS_COUNT } from "@/store/modules/recruiter/constants";
import router from "@/router";

export default Vue.extend({
  name: "DashboardNumbers",
  data(): DashboardNumbers {
    return {
      jobs: 0,
      members: 0,
      last_week_members: 0,
      last_week_jobs: 0,
      jobs_percentage: "0",
      members_percentage: "0",
      unique_jobs_icon: require("@/assets/icons/bold/document-text.svg"),
      community_members_icon: require("@/assets/icons/bold/brifecase-tick.svg"),
      registered_employees_icon: require("@/assets/icons/bold/people.svg")
    };
  },
  async mounted() {
    const data = await this.fetch_jobs_count();
    this.jobs = parseInt(data.jobs);
    this.members = parseInt(data.members);
    this.$emit("memebers", this.members);
    this.last_week_members = parseInt(data.last_week_members);
    this.last_week_jobs = parseInt(data.last_week_jobs);
    if (this.jobs > 0 && this.last_week_jobs > 0) {
      if (this.jobs - this.last_week_jobs > 0)
        this.jobs_percentage = (
          (this.last_week_jobs / (this.jobs - this.last_week_jobs)) *
          100
        ).toFixed(0);
      else this.jobs_percentage = (this.last_week_jobs * 100).toFixed(0);
    }
    if (this.members > 0 && this.last_week_members > 0) {
      if (this.members - this.last_week_members > 0)
        this.members_percentage = (
          (this.last_week_members / (this.members - this.last_week_members)) *
          100
        ).toFixed(0);
      else this.members_percentage = (this.last_week_members * 100).toFixed(0);
    }
  },
  methods: {
    router() {
      return router;
    },
    ...mapActions("recruiter", {
      fetch_jobs_count: ALL_JOBS_COUNT
    }),
    transform_unique_jobs_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#FF8473");
      return icon;
    },
    transform_community_members_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#EE49FD");
      return icon;
    },
    transform_registered_employees_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#33BFFF");
      return icon;
    }
  }
});
